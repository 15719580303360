


































import {Component, Emit, Mixins, Prop} from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import XForm from "../SimpleForm.vue";
import XButton from "../SimpleButton.vue";
import AppApiMixin from "../mixins/AppApi.vue";
import { plugEDOToContractForm } from "@/assets/scripts/form-fields/main";
import {Contract} from "@/models/contract";

const { values, attrs, validations, errors } = plugEDOToContractForm;

@Component({
  components: {
    XForm,
    XButton,
  },
  mixins: [validationMixin],
  computed: errors,
  validations,
})

class PlugEDOToContractForm extends Mixins(XForm, AppApiMixin) {
  @Prop({ required: true }) readonly contract!: Contract;

  [x: string]: any;
  values = values;
  allFieldAttrs = attrs;
  useSubmitButtonLoading = false;
  submitSuccess = '';
  submitError = "";
  mounted() {
    this.values = {...this.values, ...this.$attrs};
    this.defaultValues = {...this.values, ...this.$attrs};
  }
  async submitForm() {
    try{
      await this.plugEDOToContractRequest(this.contract['$номерЗаписи'])
      await this.chooseContract(this.contract)
    } catch (err){}
    this.emitCloseBlockEDO()
  }
  @Emit()
  emitCloseBlockEDO(){
    this.$emit("closeBlockEDO");
  }
}
  export default PlugEDOToContractForm;
