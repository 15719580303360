































import { Component, Vue } from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import XTabs from "@/components/SimpleTabs.vue";
import ParamTabContent from "@/components/for-contract-card/ParamTabContent.vue";
import PointTabContent from "@/components/for-contract-card/PointTabContent.vue";
import {mapGetters, mapState} from "vuex";
import XButton from "@/components/SimpleButton.vue";
import XCard from "@/components/SimpleCard.vue";
import XForm from "@/components/SimpleForm.vue";
import {plugEDOToContractForm} from "@/assets/scripts/form-fields/main";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import PlugEDOToContractForm
  from "@/components/for-contract-card/PlugEDOToContractForm.vue";

const { values } = plugEDOToContractForm;

  @Component({
  components: {
    Page,
    XTabs,
    ParamTabContent,
    PointTabContent,
    XButton,
    XCard,
    XForm,
    PlugEDOToContractForm
  },
  computed: {
    ...mapGetters({
      contractType: "contract/type",
      organizationType: "organization/organizationType"
    }),
    ...mapState({ currentContract: "contract" }),
  },
})
class ContractCard extends Vue {
  values = values
  showBlockEDO = false;
  titles = ["Характеристики", "Точки учета"];
  components = [ParamTabContent, PointTabContent];
  activeTabIndex = 0;

  showBlockSetParameterEDO(){
    this.showBlockEDO = !this.showBlockEDO
  }
}

export default ContractCard;
